import React, {useState} from "react";
import "./style.scss";
import NoImage from "../../../../assets/images/empty.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useFirebase} from "../../../../hooks/useFirebase";

const Item = ({item, category}) => {
	const [edit, setEdit] = useState(false);
	const {t} = useTranslation();
	const navigate = useNavigate();
	const { toggleAvailability } = useFirebase();

	const editHandler = () => {
		navigate(`/admin/edit/${item.index}`);
		// setEdit(true);
	};

	const availableHandler = async () => {
		await toggleAvailability(item.index, !item.available);
	}

	return (
		<div className='item'>
			{/*{edit && (*/}
			{/*  <div className={"item__edit"}>*/}
			{/*    <Edit editItem={item} cancel={() => setEdit(false)} />*/}
			{/*  </div>*/}
			{/*)}*/}
			<div className='item__image'>
				{item.image ? (
					<img
						src={item.image}
						className='item__image--pic'
						alt=''
					/>
				) : (
					<img
						src={NoImage}
						className='item__image--pic'
						alt=''
					/>
				)}
			</div>
			<div>
				<div className='item__data'>
					<p className='item__index'>
						<span className='item__simpleText'>Індекс:</span> {item.index}
					</p>
					<p className='item__title'>
						<span className='item__simpleText'>Наіменування Укр:</span>{" "}
						{item.title.ua}
					</p>
					<p className='item__title'>
						<span className='item__simpleText'>Наіменування En:</span>{" "}
						{item.title.en}
					</p>
				</div>
				<p className='item__description'>
					<span className='item__simpleText'>Опис Укр:</span>{" "}
					{item.description.ua}
				</p>
				<p className='item__description'>
					<span className='item__simpleText'>Опис En:</span>{" "}
					{item.description.en}
				</p>
				<p className='item__category'>
					<span className='item__simpleText'>Категорія товару:</span> {category}
				</p>
				<div className='item__details'>
					<p className='item__price'>
						<span className='item__price--num item__simpleText'>Ціна:</span>{" "}
						{item.price} грн.
					</p>
					<p className='item__price'>
            <span className='item__price--num item__simpleText'>
              Кількість:
            </span>{" "}
						{item.weight} {t(item.unit)}.
					</p>
					<p className='item__price'>
						<span className='item__price--num item__simpleText'>Гострота:</span>{" "}
						{parseInt(item.hot) === 3
							? "Дуже гостре"
							: parseInt(item.hot) === 2
								? "Гостре"
								: parseInt(item.hot) === 1
									? "Трохи гостре"
									: "Не гостре"}
						.
					</p>
				</div>
				<p className='item__price'>
            <span className='item__price--num item__simpleText'>
              Наявність:
            </span>
					<input
						type={"checkbox"}
						checked={item?.available}
						onChange={availableHandler}
						className={'item__checkbox'}
					/>
					<span className={item.available ? "item__av" : "item__nav"}>
              {item.available ? "Доступно" : "Немає"}.
            </span>
				</p>
			</div>
			<div className='item__buttons'>
        <span
	        className='item__buttons--edit'
	        onClick={editHandler}
        >
          Редагувати
        </span>
			</div>
		</div>
	);
};

export default Item;
