import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {getDownloadURL, getStorage, ref as storageRef, uploadBytes,} from "firebase/storage";
import {useDispatch} from "react-redux";
import {setAnnouncement, setLoading, setMenuItems,} from "../store/slices/menu";
import {v4} from "uuid";
import {removeToken} from "../store/slices/user";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
	getFirebaseAnnouncement,
	getFirestoreItems,
	patchFirebaseItem,
	patchFirebaseItemIsAvailable,
	postFirebaseAnnouncement
} from "../services/firestoreUtil";

export function useFirebase() {
	const storage = getStorage();
	const auth = getAuth();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {t} = useTranslation();

	const logout = () => {
		dispatch(removeToken());
		navigate("/login");
	};

	const getFbItems = async () => {
		const firestoreItems = await getFirestoreItems();
		dispatch(setMenuItems(firestoreItems));

	};

	const getFbAnnouncement = async () => {
		const {announcement} = await getFirebaseAnnouncement();
		dispatch(setAnnouncement({ua: announcement.ua, en: announcement.en}));
	};

	const getFbData = async (key) => {
		dispatch(setLoading(true));
		try {
			await getFbItems();
			await getFbAnnouncement();

			dispatch(setLoading(false));
			return true;
		} catch (e) {
			alert(t("badLoad"));
			dispatch(setLoading(false));
			return false;
		}
	};

	const updateFbAnnouncement = async (data) => {
		try {
			await postFirebaseAnnouncement(data);
			await getFbAnnouncement();
			console.log("Announcement update success");
		} catch (e) {
			console.log("Announcement update error: ");
			logout();
			return false;
		}
	};

	const fbAuth = async ({email, password}) => {
		try {
			return await signInWithEmailAndPassword(auth, email, password);
		} catch (e) {
			if (
				e.message.indexOf("auth/wrong-password") !== -1 ||
				e.message.indexOf("auth/invalid-email") !== -1 ||
				e.message.indexOf("auth/user-not-found") !== -1
			) {
				return "authError";
			} else {
				return "unknown";
			}
		}
	};

	const updateItem = async ({index, data}) => {
		try {
			await patchFirebaseItem({index, data});
			await getFbItems();
			console.log("Item update success");
		} catch (e) {
			console.log("Item update error ");
			logout();
			return false;
		}
	};

	const toggleAvailability = async (index, status) => {
		console.log({index, status});
		try {
			await patchFirebaseItemIsAvailable({index: index.toString(), value: status});
			await getFbItems();
		} catch (e) {
			console.log('Change availability error: ', e);
		}

	};

	const fbFileUpload = async (image) => {
		if (!image) {
			return;
		}
		const imageRef = storageRef(storage, `${v4() + "-" + image.name}`); // getting image ref
		try {
			const uploadTask = await uploadBytes(imageRef, image); // uploading
			return getDownloadURL(uploadTask.ref); //getting url fo file
		} catch (e) {
			console.log("Upload error ");
			logout();
			return false;
		}
	};

	return {
		getFbData,
		fbAuth,
		updateFbAnnouncement,
		updateItem,
		fbFileUpload,
		toggleAvailability
	};
}
