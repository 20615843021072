export const commonEN = {
  title: "Han Iuuan",
  menu: "Menu",
  workTime: "Working hours",
  from: "From",
  to: "till",
  fromTo: "From 12:00 till 21:30",
  weekdays: "Day off - Tuesday",
  order: "Order time",
  till: "before 21:00",
  contacts: "Contacts",
  address: "Nezhinska str. 12, Kyiv",
  insta: "Our Instagram",
  chose: "Choose a section",
  currency: "₴",
  gram: "g",
  litr: "L",
  piece: "pс",
  close: "Close",
  expect: "Expecting",
  toMenu: "To Menu",
  delivery: "About delivery",
  about: "About us",
  deliveryDetails:
    "We offer delivery of taxi orders at the cost of the trip. The dishes you have chosen will be prepared as soon as possible and immediately sent to you. You can find out the details of the delivery order by calling us.",
  aboutDetails:
    "Welcome to Han Iuuan! We will be happy to treat you to delicious Chinese dishes, which you can enjoy it in our establishment or order the delivery. You can also place an order at a convenient time for you and pick up ready-made dishes directly at the cafe.",
  badLoad:
    "Looks like we have bad internet connection here ((( \nPlease, reload the page or try again a little later",
  defenders: "For Ukrainian Defenders",
  sale: "{{percent}}% Sale",
  favorites: "Favorites",
  gotoFavorites: "Go to the Favorites",
  code: "Code",
  quantity: "Quantity",
  dish: "Dish",
  copyOrder: "Copy the order",
  toViber: "Open Viber",
  copied: "Copied!",
  orderInfo:
    "Here you can prepare an order from your favorite dishes and, after copying, go to Viber.\n\nIf the dish is missing - it will not be displayed in the list.",
};
