export const categoryUA = [
  "Супер меню",
  "Нові страви",
  "Салат",
  "Овочі",
  "Свинина",
  "Яловичина та баранина",
  "Курка та Качка",
  "Риба та морепродукти",
  "Самовар та Суп",
  "Страви на залізі",
  "Десерт, Гарніри",
  "Напої",
];
export const categoryEN = [
  "Super menu",
  "New dishes",
  "Salad",
  "Vegetables",
  "Pork",
  "Beef and lamb",
  "Chicken and Duck",
  "Fish and Seafood",
  "Samovar and Soup",
  "Dishes on iron",
  "Dessert, side dishes",
  "Drinks",
];
export const indexes = [
  "00*",
  "0*AAA",
  "1**",
  "2**",
  "3**",
  "4**",
  "5**",
  "6**",
  "7**",
  "8**",
  "9**",
  "9**",
];

export const menuLink = [
  "super",
  "new",
  "salad",
  "vegs",
  "pork",
  "beef-lamb",
  "chicken-duck",
  "fish-seafood",
  "samovar-soup",
  "iron",
  "desserts-side",
  "drinks",
];

export const PHONE_NUMBER = "+380936908298";
