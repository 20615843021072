import {collection, doc, getDoc, getDocs, getFirestore, query, updateDoc, where} from "firebase/firestore";

export async function getFirestoreItems() {
	const db = await getFirestore();
	const querySnapshot = await getDocs(collection(db, "haniuuan", "v2", "items"));
	return querySnapshot.docs.map(doc => doc.data());
}

export async function getFirebaseItemsByChapter(chapter) {
	const db = await getFirestore();
	const querySnapshot = await getDocs(
		query(collection(db, "haniuuan", "v2", "items"), where("category", "==", chapter))
	);

	return querySnapshot.docs.map(doc => doc.data());
}

export async function getFirebaseAnnouncement() {
	const db = await getFirestore();
	const docRef = doc(db, "haniuuan", "v2");
	const docSnap = await getDoc(docRef);
	return docSnap.data();
}

export async function postFirebaseAnnouncement(data) {
	const db = await getFirestore();
	const annRef = doc(db, "haniuuan", "v2");
	await updateDoc(annRef, {announcement: data});
}

export async function patchFirebaseItem(item) {
	const db = await getFirestore();
	const docRef = doc(db, "haniuuan", "v2", "items", item.index);
	await updateDoc(docRef, item.data);
}

export async function patchFirebaseItemIsAvailable(item) {
	const db = await getFirestore();
	const docRef = doc(db, "haniuuan", "v2", "items", item.index);
	await updateDoc(docRef, {available: item.value});
}
