export const commonUA = {
  title: "Хан Юуан",
  menu: "Меню",
  workTime: "Ми працюємо",
  from: "З",
  to: "по",
  fromTo: "З 12:00 по 21:30",
  weekdays: "Вихідний - Вівторок",
  order: "Замовлення страв",
  till: "до 21:00",
  contacts: "Контакти",
  address: "м. Київ, Ніжинська, 12",
  insta: "Ми у Інстаграмі",
  chose: "Оберіть розділ",
  currency: "грн",
  gram: "г",
  litr: "л",
  piece: "шт",
  close: "Закрити",
  expect: "Очікуємо",
  toMenu: "До Меню",
  delivery: "Про доставку",
  about: "Про наш заклад",
  deliveryDetails:
    "У нас працює доставка замовлень на таксі по вартості поїздки. Обрані Вами страви, будуть приготовані якнайшвидше і одразу відправлені до Вас. Деталі замовлення на доставку Ви можете дізнатись, зателефонувавши до нашого закладу.",
  aboutDetails:
    "Ласкаво просимо до нашого затишного кафе Хан Юуан! Ми з задоволенням пригостимо Вас смачними китайськими стравами, якими Ви зможете насолодитись  як у нашому закладі, так і замовивши доставку. Також Ви можете зробити замовлення на зручний для Вас час і забрати готові страви безпосередньо в кафе.",
  badLoad:
    "Схоже у нас інтернет почуває себе не дуже добре ((( \nБудь ласка, оновите сторінку або спробуйте трохи пізніше",
  defenders: "Для захисників України",
  sale: "Знижка {{percent}}%",
  favorites: "Обрані страви",
  gotoFavorites: "Перейти до обраних страв",
  code: "Код",
  quantity: "Кількість",
  dish: "Страва",
  copyOrder: "Скопіювати замовлення",
  toViber: "Відкрити Вайбер",
  copied: "Скопійовано!",
  orderInfo:
    "Тут Ви можете підготувати замовлення із Ваших улюблених страв і скопіювавши - перейти до Вайбер.\n\nЯкщо страва відсутня - вона не буде відображена у списку.",
};
